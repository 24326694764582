import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    stakingToken: tokens.cake,
    earningToken: tokens.cake,
    contractAddress: {
      97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
      56: '0x46777d443A2e41091fCC5e305D7caf64BBa455Aa',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
  },
  // {
  //   sousId: 213,
  //   stakingToken: tokens.cake,
  //   earningToken: tokens.pha,
  //   contractAddress: {
  //     97: '',
  //     56: '0x2E101b5F7f910F2609e5AcE5f43bD274b1DE09AA',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   sortOrder: 999,
  //   tokenPerBlock: '0.28',
  // },
  
]

export default pools
